import React from 'react'
import styled from 'styled-components'

function ImageHomeGallery() {
  return (
    <Wrapper>
        
    </Wrapper>
  )
}
const Wrapper = styled.section`

`;
export default ImageHomeGallery