export const NoticeList = [
   
            {
                id:1,
                title:"Book List 2023-24",
                notice:"1.pdf"
            },
            {
                id:2,
                title:"Pre Board Date Sheet X 2022-23 ",
                notice:"2.pdf"
            },
            {
                id:3,
                title:"Pre Board Date Sheet XII 2022-23 ",
                notice:"3.pdf"
            },
            {
                id:4,
                title:"School Calender ",
                notice:"3.pdf"
            },
            {
                id:5,
                title:"Gallery ",
                notice:"3.pdf"
            },
            {
                id:6,
                title:"Co-Currlicular Activities ",
                notice:"3.pdf"
            },

        ]
    

